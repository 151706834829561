body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Raleway";
  letter-spacing: 1.5px;
}

.no-scrolling {
  overflow: hidden;
}
